import { createTrackerForCategory } from '@gonfalon/analytics';
import { PostAIConfigRequestBody } from '@gonfalon/openapi';

// AI Configs
const trackAIConfigs = createTrackerForCategory('AI Configs');
const trackCreateAIConfig = () => createTrackerForCategory('AI Config Created');
const trackAIConfigCreateButtonClick = () => trackAIConfigs('Create AI Config Button Clicked');
const trackAIConfigCreated = ({ config }: { config: PostAIConfigRequestBody }) =>
  trackAIConfigs('AI Config Created', {
    config,
  });

const trackAIConfigSubmitButtonClicked = () => trackAIConfigs('Submit AI Config Button Clicked');

const trackAIConfigAddVariationButtonClicked = () => trackAIConfigs('Submit AI Config Button Clicked');

const trackAIConfigsLeftNav = () => trackAIConfigs('AI Configs Left Nav Clicked');
const trackJoinWaitlistButtonClicked = () => trackAIConfigs('Join the Waitlist Button Clicked');
const trackGiveFeedbackButtonClicked = () => trackAIConfigs('Give Feedback Button Clicked');
const trackCreateAIConfigButtonClicked = (location: string) =>
  trackAIConfigs('Create AI Config Button Clicked', { location });
const trackAIConfigCreateCancelButtonClicked = () => trackAIConfigs('AI Config Create Cancel Button Clicked');
const trackAIConfigCreateEditKeyClicked = () => trackAIConfigs('AI Config Create Edit Key Clicked');
const trackModelDropdownClickedAndSelected = (selectedOption: {
  id?: string;
  parameters?: {
    maxTokens?: number;
    temperature?: number;
  };
}) => trackAIConfigs('Model Dropdown Clicked and Selected', { selectedOption });
const trackPromptTypeDropdownClickedAndSelected = (selectedOption: string) =>
  trackAIConfigs('Prompt Type Dropdown Clicked and Selected', { selectedOption });
function trackDiscardChangesButtonClickedOnVariation(isVariationTargeted: boolean) {
  return trackAIConfigs('Discard Changes Button Clicked on Variation', { isVariationTargeted });
}
const trackSaveChangesButtonClickedOnVariation = () => trackAIConfigs('Save Changes Button Clicked on Variation');
const trackEditButtonClickedOnVariation = () => trackAIConfigs('Edit Button Clicked on Variation');
const trackPreviewButtonClickedOnPrompt = () => trackAIConfigs('Preview Button Clicked on Prompt');
const trackTemperatureChanged = () => trackAIConfigs('Temperature Changed');
const trackAddAnotherVariationButtonClicked = () => trackAIConfigs('Add Another Variation Button Clicked');
const trackDuplicateVariationButtonClicked = () => trackAIConfigs('Duplicate Variation Button Clicked');
const trackAIConfigNameUpdated = () => trackAIConfigs('AI Config Name Updated');
const trackAIConfigDescriptionUpdated = () => trackAIConfigs('AI Config Description Updated');
const trackAIConfigTagsUpdated = () => trackAIConfigs('AI Config Tags Updated');
const trackAIConfigKeyCopied = (location: string) => trackAIConfigs('AI Config Key Copied', { location });
const trackAIConfigDeleteButtonClicked = () => trackAIConfigs('AI Configs Delete Button Clicked');
const trackAIConfigDeleted = () => trackAIConfigs('AI Configs Deleted');
const trackAIConfigCopyLink = () => trackAIConfigs('AI Configs Copy Link');
const trackAIConfigChangeHistoryViewed = () => trackAIConfigs('AI Configs Change History Viewed');
const trackAIConfigShowCodeSnippetsClicked = () => trackAIConfigs('AI Config Show Code Snippets Clicked');
const trackAIConfigTargetingTabNavigation = () => trackAIConfigs('AI Configs Targeting Tab Navigation');
const trackAIConfigMonitoringTabNavigation = () => trackAIConfigs('AI Configs Monitoring Tab Navigation');
const trackAIConfigPlaygroundsTabNavigation = () => trackAIConfigs('AI Configs Playgrounds Tab Navigation');
const trackAIConfigVariationsTabNavigation = () => trackAIConfigs('AI Configs Variations Tab Navigation');

const trackAIConfigVersionNumberClicked = () => trackAIConfigs('AI Configs Versioning Version Number Clicked');
const trackAIConfigsViewHistoryButtonClicked = () => trackAIConfigs('AI Configs View History Button Clicked');
const trackAIConfigVersionHistoryDrawerOpened = () =>
  trackAIConfigs('AI Configs Versioning Version History Drawer Opened');
const trackAIVersioningPromoteClicked = () => trackAIConfigs('AI Configs Versioning Promote Clicked');

const trackSignupsForAiConfigs = (sdk: string) => {
  trackAIConfigs('Signed Up For AI Config EAP', { sdk });
};

const trackSignupsForAiConfigsWithoutSDK = () => {
  trackAIConfigs('Signed Up For AI Config EAP (No SDK)');
};

const trackAiConfigMonitoringChartSelected = (chart: string) =>
  trackAIConfigs('Ai Config Monitoring Chart Selected', { chart });

const trackAiConfigMonitoringTableDataExported = () => trackAIConfigs('Ai Config Monitoring Table Data Exported');

const trackAiConfigMonitoringChartDataExported = () => trackAIConfigs('Ai Config Monitoring Chart Data Exported');

const trackAiConfigModelRestrictionAdded = () => trackAIConfigs('Ai Config Model Restriction Added');
const trackAiConfigModelRestrictionRemoved = () => trackAIConfigs('Ai Config Model Restriction Removed');

export {
  trackCreateAIConfig,
  trackAIConfigCreateButtonClick,
  trackAIConfigCreated,
  trackAIConfigDeleted,
  trackAIConfigDeleteButtonClicked,
  trackAIConfigSubmitButtonClicked,
  trackAIConfigAddVariationButtonClicked,
  trackAIConfigsLeftNav,
  trackJoinWaitlistButtonClicked,
  trackCreateAIConfigButtonClicked,
  trackAIConfigCreateCancelButtonClicked,
  trackAIConfigCreateEditKeyClicked,
  trackModelDropdownClickedAndSelected,
  trackPromptTypeDropdownClickedAndSelected,
  trackDiscardChangesButtonClickedOnVariation,
  trackSaveChangesButtonClickedOnVariation,
  trackEditButtonClickedOnVariation,
  trackPreviewButtonClickedOnPrompt,
  trackTemperatureChanged,
  trackAddAnotherVariationButtonClicked,
  trackAIConfigNameUpdated,
  trackAIConfigDescriptionUpdated,
  trackAIConfigTagsUpdated,
  trackAIConfigKeyCopied,
  trackAIConfigCopyLink,
  trackAIConfigChangeHistoryViewed,
  trackAIConfigShowCodeSnippetsClicked,
  trackAIConfigTargetingTabNavigation,
  trackAIConfigMonitoringTabNavigation,
  trackAIConfigPlaygroundsTabNavigation,
  trackAIConfigVariationsTabNavigation,
  trackSignupsForAiConfigs,
  trackSignupsForAiConfigsWithoutSDK,
  trackGiveFeedbackButtonClicked,
  trackAIConfigVersionNumberClicked,
  trackAIConfigsViewHistoryButtonClicked,
  trackAIConfigVersionHistoryDrawerOpened,
  trackAIVersioningPromoteClicked,
  trackDuplicateVariationButtonClicked,
  trackAiConfigMonitoringChartSelected,
  trackAiConfigMonitoringTableDataExported,
  trackAiConfigMonitoringChartDataExported,
  trackAiConfigModelRestrictionAdded,
  trackAiConfigModelRestrictionRemoved,
};
